export const AvererContainer = (props) => {
  // const openSupportLink = () => {
  //   window.open(
  //     "https://averer-help.freshdesk.com/support/tickets/new",
  //     "_blank",
  //     "noopener,noreferrer"
  //   );
  // };

  return (
    <div className="averer-container">
      <div className="averer-header">
        <div>
          <img src="/averer-logo.svg" alt="Averer-logo" />
          <h2>
            Identity verification<span>_</span>
          </h2>
        </div>
        {/* TODO : Uncomment this code when need contact support button */}
        {/* <button onClick={openSupportLink} className="support-button">
          Contact Support
        </button> */}
      </div>
      {props.children}
    </div>
  );
};
